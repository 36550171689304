import { Controller } from "stimulus"

export default class extends Controller {
  product_changed() {
    let objectPrefix = this.element.getAttribute('data-object-prefix')
    let productId = this.element.querySelector(`#${objectPrefix}_product_id`).value
    
    let elements = this.element.querySelectorAll('.product-attribute')

    elements.forEach(function(el,y,z){ 
      if(el.getAttribute('data-product-id') == productId) {
        el.style.display = "block"
      } else {
        el.style.display = "none"
      }
    })

    let selectedOption = this.element.querySelector(`#${objectPrefix}_product_id option[value="${productId}"]`)
    let requiresPin = selectedOption.getAttribute('data-registration-requires-pin')

    if(requiresPin == "true")
    {
      this.element.querySelector(`#${objectPrefix}_pin_input`).style.display = 'block'
    } else {
      this.element.querySelector(`#${objectPrefix}_pin_input`).style.display = 'none'
    }

    let serialNumberPrefill = selectedOption.getAttribute('data-serial-number-prefill')
    this.element.querySelector(`#${objectPrefix}_serial_number`).value = serialNumberPrefill
  }
}
